// auth and user
export enum AuthenticationState {
  AUTHENTICATED = 'AUTHENTICATED',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  UNKNOWN = 'UNKNOWN',
}

export interface RegisterCommand {
  name: string;
  email: string;
  password: string;
}

export interface LoginCommand {
  email: string;
  password: string;
}

export interface User {
  id: string;
  email: string;
  name: string;
  creationTime: Date;
  modificationTime: Date;
}

// people

export interface CreatePersonCommand {
  name: string;
}

export interface UpdatePersonCommand {
  name: string;
}

export interface Person {
  id: string;
  name: string;
  creationTime: Date;
  modificationTime: Date;
}

// topics

export interface CreateTopicCommand {
  name: string;
  description?: string;
}

export interface UpdateTopicCommand {
  name?: string;
  description?: string;
}

export interface Topic {
  id: string;
  name: string;
  description: string;
  creationTime: Date;
  modificationTime: Date;
}

// meetings

export interface MeetingQuery {
  personId?: string;
  state?: MeetingState;
}

export interface CreateMeetingCommand {
  personId: string;
}

export interface UpdateMeetingCommand {
  personId?: string;
  state?: MeetingState;
}

export enum MeetingState {
  DRAFT = 'DRAFT',
  FINISHED = 'FINISHED',
}

export interface Meeting {
  id: string;
  personId: string;
  state: MeetingState;
  creationTime: Date;
  modificationTime: Date;
}

// actions

export interface ActionQuery {
  personId?: string;
  completed?: boolean;
  meetingId?: string;
}

export interface CreateActionCommand {
  name: string;
  meetingId: string;
}

export interface UpdateActionCommand {
  name?: string;
  completed?: boolean;
}

export interface Action {
  id: string;
  name: string;
  meetingId: string;
  personId: string;
  completed: boolean;
  creationTime: Date;
  modificationTime: Date;
}

// notes

export interface MeetingNote {
  id: string;
  index: number;
  value: string;
  topicId: string;
  creationTime: Date;
  modificationTime: Date;
}

export enum EditType {
  ADD_NOTE = 'ADD_NOTE',
  REMOVE_NOTE = 'REMOVE_NOTE',
  REORDER_NOTES = 'REORDER_NOTES',
  EDIT_NOTE = 'EDIT_NOTE',
}

export type EditMeetingNotesCommand =
  | {
      type: EditType.ADD_NOTE;
      topicId: string;
      value: string;
      orderBefore?: string;
    }
  | {
      type: EditType.REMOVE_NOTE;
      noteId: string;
    }
  | {
      type: EditType.REORDER_NOTES;
      from: string;
      to: string;
    }
  | {
      type: EditType.EDIT_NOTE;
      noteId: string;
      topicId?: string;
      value?: string;
    };
