// TODO: Need to split this apart into different files based on resource type

import {
  RefetchOptions,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useSetAuthenticationState } from '../authentication/Authentication';
import {
  register,
  fetchSelf,
  ApiError,
  logout,
  login,
  getPeople,
  createPerson,
  updatePerson,
  getPerson,
  deletePerson,
  getTopic,
  getTopics,
  createTopic,
  updateTopic,
  deleteTopic,
  findMeetings,
  getMeeting,
  createMeeting,
  updateMeeting,
  deleteMeeting,
  findActions,
  getAction,
  createAction,
  updateAction,
  deleteAction,
  getMeetingNotes,
  editMeetingNotes,
} from './api';
import {
  ActionQuery,
  AuthenticationState,
  CreateMeetingCommand,
  CreatePersonCommand,
  CreateTopicCommand,
  LoginCommand,
  Meeting,
  MeetingQuery,
  Person,
  RegisterCommand,
  Topic,
  UpdateMeetingCommand,
  UpdatePersonCommand,
  UpdateTopicCommand,
  User,
  Action,
  CreateActionCommand,
  UpdateActionCommand,
  MeetingNote,
  EditMeetingNotesCommand,
  EditType,
} from './types';

const QueryKey = {
  SELF: 'self',
  PEOPLE: 'people',
  PERSON: (id: string) => [QueryKey.PEOPLE, id],
  TOPICS: 'topics',
  TOPIC: (id: string) => [QueryKey.TOPICS, id],
  MEETINGS: (query?: MeetingQuery) => (query ? ['meetings', query] : 'meetings'),
  MEETING: (id: string) => ['meetings', id],
  MEETING_NOTES: (meetingId: string) => ['meetingNotes', meetingId],
  ACTIONS: (query?: ActionQuery) => (query ? ['actions', query] : 'actions'),
  ACTION: (id: string) => ['actions', id],
};

// Authentication

function useFetchSelf(options: UseQueryOptions<User, ApiError, User, string> = {}): UseQueryResult<User, ApiError> {
  return useQuery(QueryKey.SELF, () => fetchSelf(), options);
}

export function useSelf(options: UseQueryOptions<User, ApiError, User, string> = {}): User | null {
  const query = useFetchSelf(options);
  return query.data || null;
}

export function useRegistering() {
  const setAuthenticationState = useSetAuthenticationState();
  const queryClient = useQueryClient();
  const mutation = useMutation<User, ApiError, RegisterCommand, unknown>((user: RegisterCommand) => register(user), {
    mutationKey: 'register',
    onSuccess(user) {
      queryClient.setQueryData(QueryKey.SELF, user);
    },
  });
  return mutationWrapper(mutation, {
    async register(user: RegisterCommand) {
      const result = await mutation.mutateAsync(user);
      setAuthenticationState(AuthenticationState.AUTHENTICATED);
      return result;
    },
    user: mutation.data,
  });
}

export function useLogin() {
  const setAuthenticationState = useSetAuthenticationState();
  const queryClient = useQueryClient();
  const mutation = useMutation<User, ApiError, LoginCommand, unknown>((user: LoginCommand) => login(user), {
    mutationKey: 'login',
    onSuccess(user) {
      queryClient.setQueryData(QueryKey.SELF, user);
    },
  });
  return mutationWrapper(mutation, {
    async login(user: LoginCommand) {
      const result = await mutation.mutateAsync(user);
      setAuthenticationState(AuthenticationState.AUTHENTICATED);
      return result;
    },
    user: mutation.data,
  });
}

export function useLogout(): () => Promise<void> {
  const queryClient = useQueryClient();
  const setAuthenticationState = useSetAuthenticationState();
  return async () => {
    await logout();
    queryClient.removeQueries();
    setAuthenticationState(AuthenticationState.UNAUTHENTICATED);
  };
}

export function useAuthenticationStatus(
  options: UseQueryOptions<User, ApiError, User, string> = {}
): AuthenticationState {
  const query = useFetchSelf({ retry: false, ...options });
  if (query.data) {
    return AuthenticationState.AUTHENTICATED;
  }

  if (query.error && query.error.status === 401) {
    return AuthenticationState.UNAUTHENTICATED;
  }

  return AuthenticationState.UNKNOWN;
}

// People

export function usePeople(options: UseQueryOptions<Person[], ApiError, Person[], string> = {}) {
  const query = useQuery(QueryKey.PEOPLE, () => getPeople(), options);
  return queryWrapper(query, {
    people: query.data,
  });
}

export function usePerson(id: string, options: UseQueryOptions<Person | null, ApiError, Person, string[]> = {}) {
  const queryClient = useQueryClient();
  const query = useQuery(QueryKey.PERSON(id), () => getPerson(id), {
    initialData: () => queryClient.getQueryData<Person[]>(QueryKey.PEOPLE)?.find((p) => p.id === id),
    initialDataUpdatedAt: () => queryClient.getQueryState(QueryKey.PEOPLE)?.dataUpdatedAt,
    ...options,
  });
  return queryWrapper(query, {
    person: query.data,
  });
}

export function usePersonCreation() {
  const queryClient = useQueryClient();
  const mutation = useMutation<Person, ApiError, CreatePersonCommand, unknown>(
    (person: CreatePersonCommand) => createPerson(person),
    {
      mutationKey: 'createPerson',
      onSuccess(person) {
        const existingPeopleState = queryClient.getQueryState(QueryKey.PEOPLE);
        if (existingPeopleState) {
          queryClient.setQueryData(QueryKey.PEOPLE, (people: Person[] = []) => [...people, person]);
        }
        queryClient.setQueryData(QueryKey.PERSON(person.id), person);
      },
    }
  );
  return mutationWrapper(mutation, {
    async createPerson(person: CreatePersonCommand) {
      return await mutation.mutateAsync(person);
    },
    person: mutation.data,
  });
}

export function usePersonUpdating() {
  const queryClient = useQueryClient();
  const mutation = useMutation<Person, ApiError, { id: string; person: UpdatePersonCommand }, unknown>(
    ({ id, person }) => updatePerson(id, person),
    {
      mutationKey: 'updatePerson',
      onSuccess(result) {
        const existingPeopleState = queryClient.getQueryState(QueryKey.PEOPLE);
        if (existingPeopleState) {
          queryClient.setQueryData(QueryKey.PEOPLE, (people: Person[] = []) =>
            people.map((person) => {
              if (person.id === result.id) {
                return result;
              }
              return person;
            })
          );
        }
        queryClient.setQueryData(QueryKey.PERSON(result.id), result);
      },
    }
  );
  return mutationWrapper(mutation, {
    async updatePerson(id: string, person: UpdatePersonCommand) {
      await mutation.mutateAsync({ id, person });
    },
    person: mutation.data,
  });
}

export function usePersonDeletion() {
  const queryClient = useQueryClient();
  const mutation = useMutation<Person, ApiError, string, unknown>((id) => deletePerson(id), {
    mutationKey: 'deletePerson',
    onSuccess(result) {
      const existingPeopleState = queryClient.getQueryState(QueryKey.PEOPLE);
      if (existingPeopleState) {
        queryClient.setQueryData(QueryKey.PEOPLE, (people: Person[] = []) =>
          people.filter((person) => person.id !== result.id)
        );
      }
      queryClient.setQueryData(QueryKey.PERSON(result.id), null);
    },
  });
  return mutationWrapper(mutation, {
    async deletePerson(id: string) {
      await mutation.mutateAsync(id);
    },
    person: mutation.data,
  });
}

// Topics
export function useTopics(options: UseQueryOptions<Topic[], ApiError, Topic[], string> = {}) {
  const query = useQuery(QueryKey.TOPICS, () => getTopics(), options);
  return queryWrapper(query, {
    topics: query.data,
  });
}

export function useTopic(id: string, options: UseQueryOptions<Topic | null, ApiError, Topic, string[]> = {}) {
  const queryClient = useQueryClient();
  const query = useQuery(QueryKey.TOPIC(id), () => getTopic(id), {
    initialData: () => queryClient.getQueryData<Topic[]>(QueryKey.TOPICS)?.find((p) => p.id === id),
    initialDataUpdatedAt: () => queryClient.getQueryState(QueryKey.TOPICS)?.dataUpdatedAt,
    ...options,
  });
  return queryWrapper(query, {
    topic: query.data,
  });
}

export function useTopicCreation() {
  const queryClient = useQueryClient();
  const mutation = useMutation<Topic, ApiError, CreateTopicCommand, unknown>(
    (topic: CreateTopicCommand) => createTopic(topic),
    {
      mutationKey: 'createTopic',
      onSuccess(topic) {
        const existingTopicsState = queryClient.getQueryState(QueryKey.TOPICS);
        if (existingTopicsState) {
          queryClient.setQueryData(QueryKey.TOPICS, (topics: Topic[] = []) => [...topics, topic]);
        }
        queryClient.setQueryData(QueryKey.TOPIC(topic.id), topic);
      },
    }
  );
  return mutationWrapper(mutation, {
    async createTopic(topic: CreateTopicCommand) {
      return await mutation.mutateAsync(topic);
    },
    topic: mutation.data,
  });
}

export function useTopicUpdating() {
  const queryClient = useQueryClient();
  const mutation = useMutation<Topic, ApiError, { id: string; command: UpdateTopicCommand }, unknown>(
    ({ id, command }) => updateTopic(id, command),
    {
      mutationKey: 'updateTopic',
      onSuccess(result) {
        const existingTopicsState = queryClient.getQueryState(QueryKey.TOPICS);
        if (existingTopicsState) {
          queryClient.setQueryData(QueryKey.TOPICS, (topics: Topic[] = []) =>
            topics.map((topic) => {
              if (topic.id === result.id) {
                return result;
              }
              return topic;
            })
          );
        }
        queryClient.setQueryData(QueryKey.TOPIC(result.id), result);
      },
    }
  );
  return mutationWrapper(mutation, {
    async updateTopic(id: string, command: UpdateTopicCommand) {
      await mutation.mutateAsync({ id, command });
    },
    topic: mutation.data,
  });
}

export function useTopicDeletion() {
  const queryClient = useQueryClient();
  const mutation = useMutation<Topic, ApiError, string, unknown>((id) => deleteTopic(id), {
    mutationKey: 'deleteTopic',
    onSuccess(result) {
      const existingTopicsState = queryClient.getQueryState(QueryKey.TOPICS);
      if (existingTopicsState) {
        queryClient.setQueryData(QueryKey.TOPICS, (topics: Topic[] = []) =>
          topics.filter((topic) => topic.id !== result.id)
        );
      }
      queryClient.setQueryData(QueryKey.TOPIC(result.id), null);
    },
  });
  return mutationWrapper(mutation, {
    async deleteTopic(id: string) {
      await mutation.mutateAsync(id);
    },
    topic: mutation.data,
  });
}

// Actions
export function useActions(actionQuery: ActionQuery, options: UseQueryOptions<Action[], ApiError, Action[], any> = {}) {
  const query = useQuery(QueryKey.ACTIONS(actionQuery), () => findActions(actionQuery), options);
  return queryWrapper(query, {
    actions: query.data,
  });
}

export function useAction(id: string, options: UseQueryOptions<Action | null, ApiError, Action, string[]> = {}) {
  // TODO: add cache usage by updating react query and using getQueriesData
  const query = useQuery(QueryKey.ACTION(id), () => getAction(id), {
    ...options,
  });
  return queryWrapper(query, {
    action: query.data,
  });
}

export function useActionCreation() {
  const queryClient = useQueryClient();
  const mutation = useMutation<Action, ApiError, CreateActionCommand, unknown>(
    (command: CreateActionCommand) => createAction(command),
    {
      mutationKey: 'createAction',
      onSuccess(action) {
        // TODO: add it to actions query data as well
        queryClient.setQueryData(QueryKey.ACTION(action.id), action);
      },
    }
  );
  return mutationWrapper(mutation, {
    async createAction(command: CreateActionCommand) {
      return await mutation.mutateAsync(command);
    },
    action: mutation.data,
  });
}

export function useActionUpdating() {
  const queryClient = useQueryClient();
  const mutation = useMutation<Action, ApiError, { id: string; command: UpdateActionCommand }, unknown>(
    ({ id, command }) => updateAction(id, command),
    {
      mutationKey: 'updateAction',
      onSuccess(result) {
        // TODO: add it to actions query data as well
        queryClient.setQueryData(QueryKey.ACTION(result.id), result);
      },
    }
  );
  return mutationWrapper(mutation, {
    async updateAction(id: string, command: UpdateActionCommand) {
      await mutation.mutateAsync({ id, command });
    },
    action: mutation.data,
  });
}

export function useActionDeletion() {
  const queryClient = useQueryClient();
  const mutation = useMutation<Action, ApiError, string, unknown>((id) => deleteAction(id), {
    mutationKey: 'deleteAction',
    onSuccess(result) {
      // TODO: remove it from actions query data as well
      queryClient.setQueryData(QueryKey.ACTION(result.id), null);
    },
  });
  return mutationWrapper(mutation, {
    async deleteAction(id: string) {
      await mutation.mutateAsync(id);
    },
    action: mutation.data,
  });
}

// Meetings
export function useMeetings(
  meetingQuery: MeetingQuery = {},
  options: UseQueryOptions<Meeting[], ApiError, Meeting[], any> = {}
) {
  const query = useQuery(QueryKey.MEETINGS(meetingQuery), () => findMeetings(meetingQuery), options);
  return queryWrapper(query, {
    meetings: query.data,
  });
}

export function useMeeting(id: string, options: UseQueryOptions<Meeting | null, ApiError, Meeting, string[]> = {}) {
  // TODO: add cache usage by updating react query and using getQueriesData
  const query = useQuery(QueryKey.MEETING(id), () => getMeeting(id), {
    ...options,
  });
  return queryWrapper(query, {
    meeting: query.data,
  });
}

export function useMeetingCreation() {
  const queryClient = useQueryClient();
  const mutation = useMutation<Meeting, ApiError, CreateMeetingCommand, unknown>(
    (command: CreateMeetingCommand) => createMeeting(command),
    {
      mutationKey: 'createMeeting',
      onSuccess(meeting) {
        const meetingsKey = QueryKey.MEETINGS({ personId: meeting.personId, state: meeting.state });
        const existingMeetings: Meeting[] = queryClient.getQueryData(meetingsKey) || [];
        queryClient.setQueryData(meetingsKey, [meeting, ...existingMeetings]);
        queryClient.setQueryData(QueryKey.MEETING(meeting.id), meeting);
      },
    }
  );
  return mutationWrapper(mutation, {
    async createMeeting(command: CreateMeetingCommand) {
      return await mutation.mutateAsync(command);
    },
    meeting: mutation.data,
  });
}

export function useMeetingUpdating() {
  const queryClient = useQueryClient();
  const mutation = useMutation<Meeting, ApiError, { id: string; command: UpdateMeetingCommand }, unknown>(
    ({ id, command }) => updateMeeting(id, command),
    {
      mutationKey: 'updateMeeting',
      onSuccess(result) {
        // TODO: add it to meetings query data as well, or mark it as dirty
        queryClient.setQueryData(QueryKey.MEETING(result.id), result);
      },
    }
  );
  return mutationWrapper(mutation, {
    async updateMeeting(id: string, command: UpdateMeetingCommand) {
      await mutation.mutateAsync({ id, command });
    },
    meeting: mutation.data,
  });
}

export function useMeetingDeletion() {
  const queryClient = useQueryClient();
  const mutation = useMutation<Meeting, ApiError, string, unknown>((id) => deleteMeeting(id), {
    mutationKey: 'deleteMeeting',
    onSuccess(result) {
      // TODO: remove it from meetings query data as well
      queryClient.setQueryData(QueryKey.MEETING(result.id), null);
    },
  });
  return mutationWrapper(mutation, {
    async deleteMeeting(id: string) {
      await mutation.mutateAsync(id);
    },
    meeting: mutation.data,
  });
}

// Meeting notes
export function useMeetingNotes(
  meetingId: string,
  options: UseQueryOptions<MeetingNote[], ApiError, MeetingNote[], any> = {}
) {
  const query = useQuery(QueryKey.MEETING_NOTES(meetingId), () => getMeetingNotes(meetingId), options);
  return queryWrapper(query, {
    notes: query.data,
  });
}

export function useMeetingNoteEditing(meetingId: string) {
  const queryClient = useQueryClient();
  const mutation = useMutation<
    MeetingNote[],
    ApiError,
    { meetingId: string; command: EditMeetingNotesCommand },
    unknown
  >(({ meetingId, command }) => editMeetingNotes(meetingId, command), {
    mutationKey: 'updateMeetingNotes',
    onSuccess(result, { meetingId }) {
      queryClient.setQueryData(QueryKey.MEETING_NOTES(meetingId), result);
    },
  });
  return mutationWrapper(mutation, {
    async addNote(command: { topicId: string; value: string; orderBefore?: string }) {
      await mutation.mutateAsync({
        meetingId,
        command: {
          ...command,
          type: EditType.ADD_NOTE,
        },
      });
    },

    async reorderNotes(command: { from: string; to: string }) {
      await mutation.mutateAsync({
        meetingId,
        command: {
          ...command,
          type: EditType.REORDER_NOTES,
        },
      });
    },

    async deleteNote(noteId: string) {
      await mutation.mutateAsync({
        meetingId,
        command: {
          noteId,
          type: EditType.REMOVE_NOTE,
        },
      });
    },

    async editNote(noteId: string, command: { topicId?: string; value?: string }) {
      await mutation.mutateAsync({
        meetingId,
        command: {
          noteId,
          ...command,
          type: EditType.EDIT_NOTE,
        },
      });
    },

    notes: mutation.data,
  });
}

// Utilities
function mutationWrapper<A, B, C, D, T>(
  { isIdle, isLoading, isSuccess, isError, error }: UseMutationResult<A, B, C, D>,
  overrides: T
): {
  error: null | B;
  isIdle: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
} & T {
  return {
    error,
    isIdle,
    isLoading,
    isSuccess,
    isError,
    ...overrides,
  };
}

function queryWrapper<QueryT, ErrorT, OverrideT>(
  { isIdle, isLoading, isSuccess, isError, error, refetch }: UseQueryResult<QueryT, ErrorT>,
  overrides: OverrideT
): {
  error: null | ErrorT;
  isIdle: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  refetch: (options?: RefetchOptions | undefined) => Promise<UseQueryResult<QueryT, ErrorT>>;
} & OverrideT {
  return {
    error,
    isIdle,
    isLoading,
    isSuccess,
    isError,
    refetch,
    ...overrides,
  };
}
