import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import styles from './AppLayout.module.scss';
import { useLogout } from '../../api/hooks';
import {
  Home as HomeIcon,
  Meeting as MeetingIcon,
  People as PeopleIcon,
  Action as ActionIcon,
  Tool as ToolIcon,
  Heart as HeartIcon,
  Gear as GearIcon,
} from '../../design/icons/Icons';
import { Logo } from '../../design/icons/Logo';
import { AnimatePresence, AnimateSharedLayout } from 'framer-motion';

export const AppLayout: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <Fragment>
      <Navigation />
      <main className={classNames(styles.layout, 'py-4 px-4')}>
        <AnimatePresence exitBeforeEnter>
          <AnimateSharedLayout>{children}</AnimateSharedLayout>
        </AnimatePresence>
      </main>
    </Fragment>
  );
};

const Navigation: React.FunctionComponent = () => {
  const logOut = useLogout();
  return (
    <nav className={classNames('pt-4 px-2 pb-2', styles.navigation)}>
      <div className={classNames(styles.logoContainer)}>
        <Logo />
        <h3>Paver</h3>
      </div>
      <ul className={classNames('list-unstyled', styles.list)}>
        <NavigationItem to="/home">
          <HomeIcon /> Home
        </NavigationItem>
        <NavigationItem to="/meetings">
          <MeetingIcon /> Meetings
        </NavigationItem>
        <NavigationItem to="/people">
          <PeopleIcon /> People
        </NavigationItem>
        <NavigationItem to="/actions">
          <ActionIcon /> Action items
        </NavigationItem>
        <NavigationItem to="/tools">
          <ToolIcon /> Tools
        </NavigationItem>
        <NavigationItem to="/feedback">
          <HeartIcon /> Feedback
        </NavigationItem>
      </ul>

      <div className={classNames('px-2', styles.bottom)}>
        <ul className={classNames('list-unstyled', styles.list)}>
          <NavigationItem to="/settings">
            <GearIcon /> Settings
          </NavigationItem>
          <li onClick={() => logOut()}>Sign out</li>
        </ul>
      </div>
    </nav>
  );
};

const NavigationItem: React.FunctionComponent<{ to: string; children: React.ReactNode }> = ({ to, children }) => {
  return (
    <li>
      <NavLink
        to={to}
        end
        className={({ isActive }) =>
          classNames('px-4 py-2 d-block', styles.item, {
            [styles.active]: isActive,
          })
        }
      >
        <span className={styles.itemContent}>{children}</span>
      </NavLink>
    </li>
  );
};
