import { FiHome } from 'react-icons/fi';
import { AiOutlineTeam } from 'react-icons/ai';
import { CgNotes } from 'react-icons/cg';
import { BiTask } from 'react-icons/bi';
import { FiTool } from 'react-icons/fi';
import { FiHeart } from 'react-icons/fi';
import { BsGearFill } from 'react-icons/bs';

export const Home = () => <FiHome />;
export const People = () => <AiOutlineTeam />;
export const Meeting = () => <CgNotes />;
export const Action = () => <BiTask />;
export const Tool = () => <FiTool />;
export const Heart = () => <FiHeart />;
export const Gear = () => <BsGearFill />;
