import React from 'react';
import ReactDOM from 'react-dom';
import './design/root.scss';
import { Paver } from './Paver';
import * as serviceWorkerRegistration from './meta/serviceWorkerRegistration';
import reportWebVitals from './meta/reportWebVitals';

const root = document.getElementById('root') as HTMLElement;
ReactDOM.createRoot(root).render(
  <React.StrictMode>
    <Paver />
  </React.StrictMode>
);

// TODO: change this to register() once we start working on PWA functionality
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
