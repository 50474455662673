import React, { ComponentType, Fragment, Suspense } from 'react';
import { BrowserRouter, MemoryRouter, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { AuthenticationProvider } from './authentication/Authentication';
import { AuthenticatedRoute } from './authentication/AuthenticatedRoute';
import { Homepage } from './staticPages/homepage/Homepage';
import { NotFoundPage } from './staticPages/NotFoundPage';
import { AppLayoutRoute } from './app/layout/AppLayoutRoute';
import { AppLayoutTransition } from './app/layout/AppLayoutTransition';

const Dashboard = lazy(() => import('./app/Dashboard'), 'Dashboard');

const LoginPage = lazy(() => import('./authentication/Login'), 'LoginPage');

const RegisterPage = lazy(() => import('./authentication/Register'), 'RegisterPage');

const PersonPage = lazy(() => import('./app/people/PersonPage'), 'PersonPage');

const CreateMeetingPage = lazy(() => import('./app/meetings/CreateMeetingPage'), 'CreateMeetingPage');

function lazy<T extends ComponentType<any>, ExportName extends string>(
  importer: () => Promise<Record<ExportName, T>>,
  exportName: ExportName
) {
  return React.lazy(async () => {
    const module = await importer();
    return { default: module[exportName] };
  });
}

function defaultQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 10000,
      },
    },
  });
}

export const Paver: React.FunctionComponent<{ queryClient?: QueryClient }> = ({
  queryClient = defaultQueryClient(),
}) => {
  // TODO: add loader for top-level suspense instead of fragment

  const Router = process.env.NODE_ENV === 'test' ? MemoryRouter : BrowserRouter;
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <AuthenticationProvider>
          <Suspense fallback={<Fragment />}>
            <AppRoutes />
          </Suspense>
        </AuthenticationProvider>
        {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />}
      </QueryClientProvider>
    </Router>
  );
};

const AppRoutes = () => {
  return (
    <Routes>
      {/* Public routes */}
      <Route path="/" element={<Homepage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/login" element={<LoginPage />} />

      {/* Authenticated routes */}
      <Route element={<AuthenticatedRoute />}>
        {/* Routes with the application layout */}
        <Route element={<AppLayoutRoute />}>
          <Route path="/home" element={<Dashboard />} />
          <Route path="/meetings" element={<AppRoutePlaceholder name="Meetings" />} />
          <Route path="/actions" element={<AppRoutePlaceholder name="Actions" />} />
          <Route path="/tools" element={<AppRoutePlaceholder name="Tools" />} />
          <Route path="/feedback" element={<AppRoutePlaceholder name="Feedback" />} />
          <Route path="/settings" element={<AppRoutePlaceholder name="Settings" />} />
          <Route path="/people" element={<AppRoutePlaceholder name="People" />} />
          <Route path="/people/:personId" element={<PersonPage />} />
        </Route>
        {/* Routes without the application layout, but still authenticated */}
        <Route path="/meetings/new" element={<CreateMeetingPage />} />
      </Route>

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

const AppRoutePlaceholder = ({ name }: { name: string }) => {
  return (
    <AppLayoutTransition id={name}>
      <h1>{name}</h1>
    </AppLayoutTransition>
  );
};
