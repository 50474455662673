import React, { Fragment } from 'react';
import { useLocation, Navigate } from 'react-router-dom';

import { useAuthenticationState } from './Authentication';
import { AuthenticationState } from '../api/types';

export interface AuthenticatedProps {
  children?: React.ReactNode;
}

export const Authenticated: React.FunctionComponent<AuthenticatedProps> = ({ children }) => {
  const authenticationState = useAuthenticationState();
  const location = useLocation();

  if (authenticationState === AuthenticationState.AUTHENTICATED) {
    return <>{children}</>;
  }

  if (authenticationState === AuthenticationState.UNAUTHENTICATED) {
    return <Navigate to={`/login?next=${encodeURIComponent(location.pathname)}`} replace />;
  }

  // TODO: replace with loader?
  return <Fragment />;
};
