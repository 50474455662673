import React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuthenticationStatus } from '../../api/hooks';
import { AuthenticationState } from '../../api/types';
import { useNavigateToDefaultRoute } from '../../common/useNavigateToDefaultRoute';

export const Homepage: React.FunctionComponent<{}> = () => {
  useRedirectToDashboardIfSignedIn();
  return (
    <div style={{ maxWidth: '1000px', paddingLeft: '64px', marginTop: '200px' }}>
      <ol style={{ padding: 0 }}>
        <li>Building great engineering teams is really impactful.</li>
        <li className="mt-2">The tooling for this sucks.</li>
        <li className="mt-2">We're building tooling for building great engineering teams.</li>
      </ol>
      <p className="mt-4">
        If you have access to the alpha, <Link to="/register">sign up</Link> or <Link to="/login">sign in</Link>.
      </p>
    </div>
  );
};

function useRedirectToDashboardIfSignedIn() {
  const status = useAuthenticationStatus();
  const navigateToDefaultRoute = useNavigateToDefaultRoute();

  useEffect(() => {
    if (status === AuthenticationState.AUTHENTICATED) {
      navigateToDefaultRoute({ replace: true });
    }
  }, [status, navigateToDefaultRoute]);
}
