import { useCallback } from 'react';
import { NavigateOptions, useNavigate } from 'react-router-dom';

export const useNavigateToDefaultRoute = () => {
  const navigate = useNavigate();

  const navigator = useCallback(
    (options?: NavigateOptions) => {
      navigate('/home', options);
    },
    [navigate]
  );
  return navigator;
};
