import http, { AxiosError } from 'axios';
import {
  CreateMeetingCommand,
  CreatePersonCommand,
  CreateTopicCommand,
  LoginCommand,
  Meeting,
  MeetingState,
  Person,
  RegisterCommand,
  Topic,
  UpdateMeetingCommand,
  UpdatePersonCommand,
  UpdateTopicCommand,
  User,
  Action,
  CreateActionCommand,
  UpdateActionCommand,
  MeetingNote,
  EditMeetingNotesCommand,
  MeetingQuery,
  ActionQuery,
} from './types';

const api = http.create({
  baseURL: '/api',
});

// User and authentication
export async function register(user: RegisterCommand): Promise<User> {
  const result = await withErrorTransform(api.post('/v1/register', user));
  return transformUser(result.data);
}

export async function login(user: LoginCommand): Promise<User> {
  const result = await withErrorTransform(api.post('/v1/login', user));
  return transformUser(result.data);
}

export async function logout(): Promise<void> {
  await withErrorTransform(api.post('/v1/logout'));
}

export async function fetchSelf(): Promise<User> {
  const result = await withErrorTransform(api.get('/v1/me'));
  return transformUser(result.data);
}

function transformUser(user: {
  id: string;
  email: string;
  name: string;
  creationTime: string;
  modificationTime: string;
}): User {
  return {
    ...user,
    creationTime: new Date(user.creationTime),
    modificationTime: new Date(user.modificationTime),
  };
}

// People

export async function createPerson(command: CreatePersonCommand): Promise<Person> {
  const result = await withErrorTransform(api.post('/v1/people', command));
  return transformPerson(result.data);
}

export async function getPeople(): Promise<Person[]> {
  const result = await withErrorTransform(api.get('/v1/people'));
  return result.data.map(transformPerson);
}

export async function getPerson(id: string): Promise<Person | null> {
  const result = await with404Transform(withErrorTransform(api.get(`/v1/people/${id}`)));
  return result ? transformPerson(result.data) : null;
}

export async function updatePerson(id: string, command: UpdatePersonCommand): Promise<Person> {
  const result = await withErrorTransform(api.patch(`/v1/people/${id}`, command));
  return transformPerson(result.data);
}

export async function deletePerson(id: string): Promise<Person> {
  const result = await withErrorTransform(api.delete(`/v1/people/${id}`));
  return transformPerson(result.data);
}

function transformPerson(person: { id: string; name: string; creationTime: string; modificationTime: string }): Person {
  return {
    ...person,
    creationTime: new Date(person.creationTime),
    modificationTime: new Date(person.modificationTime),
  };
}

// Topics

export async function getTopics(): Promise<Topic[]> {
  const result = await withErrorTransform(api.get('/v1/topics'));
  return result.data.map(transformTopic);
}

export async function getTopic(id: string): Promise<Topic | null> {
  const result = await with404Transform(withErrorTransform(api.get(`/v1/topics/${id}`)));
  return result ? transformTopic(result.data) : null;
}

export async function createTopic(command: CreateTopicCommand): Promise<Topic> {
  const result = await withErrorTransform(api.post('/v1/topics', command));
  return transformTopic(result.data);
}

export async function updateTopic(id: string, command: UpdateTopicCommand): Promise<Topic> {
  const result = await withErrorTransform(api.patch(`/v1/topics/${id}`, command));
  return transformTopic(result.data);
}

export async function deleteTopic(id: string): Promise<Topic> {
  const result = await withErrorTransform(api.delete(`/v1/topics/${id}`));
  return transformTopic(result.data);
}

function transformTopic(topic: {
  id: string;
  name: string;
  description: string;
  creationTime: string;
  modificationTime: string;
}): Topic {
  return {
    ...topic,
    creationTime: new Date(topic.creationTime),
    modificationTime: new Date(topic.modificationTime),
  };
}

// Meetings

export async function findMeetings(params: MeetingQuery = {}): Promise<Meeting[]> {
  const result = await withErrorTransform(api.get('/v1/meetings', { params }));
  return result.data.map(transformMeeting);
}

export async function getMeeting(id: string): Promise<Meeting | null> {
  const result = await with404Transform(withErrorTransform(api.get(`/v1/meetings/${id}`)));
  return result ? transformMeeting(result.data) : null;
}

export async function createMeeting(command: CreateMeetingCommand): Promise<Meeting> {
  const result = await withErrorTransform(api.post('/v1/meetings', command));
  return transformMeeting(result.data);
}

export async function updateMeeting(id: string, command: UpdateMeetingCommand): Promise<Meeting> {
  const result = await withErrorTransform(api.patch(`/v1/meetings/${id}`, command));
  return transformMeeting(result.data);
}

export async function deleteMeeting(id: string): Promise<Meeting> {
  const result = await withErrorTransform(api.delete(`/v1/topics/${id}`));
  return transformMeeting(result.data);
}

function transformMeeting(meeting: {
  id: string;
  personId: string;
  state: string;
  creationTime: string;
  modificationTime: string;
}): Meeting {
  return {
    ...meeting,
    state: meeting.state as MeetingState,
    creationTime: new Date(meeting.creationTime),
    modificationTime: new Date(meeting.modificationTime),
  };
}

// Actions
export async function findActions(params: ActionQuery = {}): Promise<Action[]> {
  const result = await withErrorTransform(api.get('/v1/actions', { params }));
  return result.data.map(transformAction);
}

export async function getAction(id: string): Promise<Action | null> {
  const result = await with404Transform(withErrorTransform(api.get(`/v1/actions/${id}`)));
  return result ? transformAction(result.data) : null;
}

export async function createAction(command: CreateActionCommand): Promise<Action> {
  const result = await withErrorTransform(api.post('/v1/actions', command));
  return transformAction(result.data);
}

export async function updateAction(id: string, command: UpdateActionCommand): Promise<Action> {
  const result = await withErrorTransform(api.patch(`/v1/actions/${id}`, command));
  return transformAction(result.data);
}

export async function deleteAction(id: string): Promise<Action> {
  const result = await withErrorTransform(api.delete(`/v1/actions/${id}`));
  return transformAction(result.data);
}

function transformAction(action: {
  id: string;
  name: string;
  meetingId: string;
  personId: string;
  completed: boolean;
  creationTime: string;
  modificationTime: string;
}): Action {
  return {
    ...action,
    creationTime: new Date(action.creationTime),
    modificationTime: new Date(action.modificationTime),
  };
}

// Notes
export async function getMeetingNotes(meetingId: string): Promise<MeetingNote[]> {
  const result = await withErrorTransform(api.get(`/v1/meetings/${meetingId}/notes`));
  return result.data.map(transformNote);
}

export async function editMeetingNotes(meetingId: string, command: EditMeetingNotesCommand): Promise<MeetingNote[]> {
  const result = await withErrorTransform(api.patch(`/v1/meetings/${meetingId}/notes`, command));
  return result.data.map(transformNote);
}

function transformNote(note: {
  id: string;
  index: number;
  value: string;
  topicId: string;
  creationTime: string;
  modificationTime: string;
}): MeetingNote {
  return {
    ...note,
    creationTime: new Date(note.creationTime),
    modificationTime: new Date(note.modificationTime),
  };
}

// Error handling

async function withErrorTransform<T>(promise: Promise<T>): Promise<T> {
  try {
    return await promise;
  } catch (error) {
    if (isAxiosError(error)) {
      throw new ApiError(error);
    }
    throw error;
  }
}

async function with404Transform<T>(promise: Promise<T>): Promise<T | null> {
  try {
    return await promise;
  } catch (error) {
    if (error instanceof ApiError && error.status === 404) {
      return null;
    }
    throw error;
  }
}

export class ApiError extends Error {
  public readonly status: number;
  public readonly errors: ApiErrorValue[];

  constructor(error: AxiosError) {
    if (error.config.method && error.response) {
      super(
        `Api ${error.config.method!.toUpperCase()} ${error.config.url} failed with ${error.response?.status}: ${
          error.response?.statusText
        }`
      );
    } else {
      super(`Unknown Api error: ${error.config.method}: ${error.config.url}`);
    }
    this.status = error.response?.status || 0;
    this.errors = error.response?.data?.errors;
  }
}

export interface ApiErrorValue {
  message: string;
}

function isAxiosError(error: Error): error is AxiosError {
  return !!(error as AxiosError).isAxiosError;
}
